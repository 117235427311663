<template>
  <a ref="btn" :href="downloadUrl" class="download" download="download">
    <span class="text">Download {{ appName || "Ask AI" }}</span>
  </a>
</template>

<script>
export default {
  props: ["downloadUrl", "appName"],
};
</script>

<style lang="scss" scoped>
.download {
  position: fixed;
  bottom: 30px;
  left: 40px;
  right: 40px;
  padding: 14px 0;
  z-index: 20;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  background-color: #4aa283;
  border-radius: 5px;
}
</style>
