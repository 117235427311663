<template>
  <div class="dialog">
    <div class="content">
      <div v-if="type !== 'loading'" class="icon-wrapper">
        <img class="loading-icon" src="@/assets/icons/failed@3x.png" />
      </div>
      <div v-else class="icon-wrapper lottie"></div>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import * as lottieData from "@/assets/icons/loading_re.json";
export default {
  name: "loading-com",
  props: ["type"],
  computed: {
    text() {
      return this.type === "loading" ? "Processing…" : "Process failed";
    },
    icon() {
      let iconName = this.type === "loading" ? "loading.gif" : "failed@3x.png";
      return require("@/assets/icons/" + iconName);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!document.querySelector(".icon-wrapper.lottie")) {
        return;
      }
      lottie.loadAnimation({
        container: document.querySelector(".icon-wrapper.lottie"), // 绑定dom节点
        renderer: "svg", // 渲染方式:svg、canvas
        loop: true, // 是否循环播放，默认：false
        autoplay: true, // 是否自动播放, 默认true
        animationData: lottieData, // AE动画使用bodymovie导出为json数据
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .content {
    height: 157px;
    width: 155px;
    background-color: #ffffff0d;
    background-color: #353541fa;
    border-radius: 8px;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon-wrapper {
      height: 58px;
      width: 58px;
    }
    .loading-icon {
      width: 58px;
      // &.loading {
      //   animation: loading 0.8s infinite;
      // }
    }
    p {
      margin-top: 16px;
    }
  }
  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
