<template>
  <header class="header">
    <a :href="downloadUrl" class="icon"
      ><img src="@/assets/icons/aichav4_topbar_icon1@3x.png" alt=""
    /></a>
    <div>{{ appName }}</div>
    <a :href="downloadUrl" class="icon"
      ><img src="@/assets/icons/aichav4_topbar_icon2@3x.png" alt=""
    /></a>
  </header>
</template>

<script>
export default {
  name: "header-comp",
  props: ["downloadUrl", "appName"],
};
</script>

<style lang="scss" scoped>
.header {
  height: 40px;
  background-color: #353541;
  box-shadow: 0 1px 0 0 #50505a;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  color: #d8d8e3;
  font-size: 16px;
  font-weight: 600;
  .icon {
    width: 20px;
    img {
      width: 20px;
    }
  }
}
</style>
