<template>
  <section class="question-list">
    <Header :downloadUrl="downloadUrl" :app-name="app_name" />
    <content-list :list="list" />
    <DownloadBtn
      v-if="downloadUrl"
      :downloadUrl="downloadUrl"
      :app-name="app_name"
    ></DownloadBtn>
    <Loading v-if="showLoading" :type="type" />
  </section>
</template>

<script>
import instance from "@/api/index";
import Header from "@/components/Header/Header.vue";
import ContentList from "@/components/ContentList/ContentList";
import DownloadBtn from "@/components/DownloadBtn/DownloadBtn";
import Loading from "@/components/Loading/Loading";
export default {
  name: "AiQes",
  components: {
    Header,
    ContentList,
    DownloadBtn,
    Loading,
  },
  data() {
    return {
      list: [],
      downloadUrl: "",
      showLoading: false,
      type: "loading",
      app_name: "",
    };
  },
  mounted() {
    const jsonId = this.$route.params && this.$route.params.catchAll;
    console.log("jsonId", jsonId, this.$route);
    if (jsonId) {
      this.showLoading = true;
      this.type = "loading";
      instance
        .get(jsonId + ".json")
        .then((res) => {
          console.log("res", res.data);
          const { list, app_store_url, app_name } = res.data;
          this.list = list;
          this.downloadUrl = app_store_url;
          this.showLoading = false;
          this.app_name = app_name;
        })
        .catch(() => {
          this.type = "failed";
          setTimeout(() => {
            this.showLoading = false;
          }, 2000);
        });
    }
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
