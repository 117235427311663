<template>
  <section class="content-list" ref="contentList">
    <div v-if="list && list.length > 0" class="content-wrapper">
      <ContentItem
        v-for="(item, index) in list"
        :key="index"
        :item="item"
        :last="index === list.length - 1"
        :usermoved="usermoved"
      />
    </div>
  </section>
</template>

<script>
import ContentItem from "./ContentItem.vue";
export default {
  name: "content-list",
  props: ["list"],
  components: {
    ContentItem,
  },
  data() {
    return {
      usermoved: false,
    };
  },
  methods: {
    checkMoved() {
      const scrollTop = document.querySelector(".content-list").scrollTop;
      const wrapperHeight =
        document.querySelector(".content-wrapper").offsetHeight;
      this.usermoved = scrollTop + window.screen.height < wrapperHeight;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector(".content-list").addEventListener("scroll", () => {
        this.checkMoved();
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.content-list {
  font-size: 16px;
  color: #fff;
  text-align: left;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  .content-wrapper {
    height: auto;
    width: 100%;
  }
}
</style>
