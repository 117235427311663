<template>
  <div ref="contentItem" class="content-item" :class="isAi ? 'ai' : 'user'">
    <div class="content" :class="this.last ? 'lastContent' : ''">
      <img :src="icon" class="icon" alt="" />
      <div v-if="!isAi" class="ask">{{ item.content }}</div>
      <div v-else class="ai-answer" ref="aiAnswer"></div>
    </div>
    <div class="copy-botton">
      <img
        @click="clickCopy"
        class="copy-icon"
        v-if="isAi && !canScroll && !copyed"
        src="@/assets/icons/copy.svg"
        alt=""
        srcset=""
      />
      <img v-if="copyed" src="@/assets/icons/duigou.svg" alt="" />
    </div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";
export default {
  props: ["item", "last", "usermoved"],
  computed: {
    isAi() {
      return this.item && this.item.role === "assistant";
    },
    icon() {
      let iconName = this.isAi ? "aichav4_chat_icon1" : "aichav4_chat_icon2";
      return require("@/assets/icons/" + iconName + "@3x.png");
    },
  },
  watch: {
    usermoved(val) {
      if (!val) {
        this.scrollHtml();
      }
    },
  },
  data() {
    return {
      delayTime: 20,
      svgIcon: require("@/assets/icons/copy.svg"),
      yesIcon: require("@/assets/icons/duigou.svg"),
      canScroll: this.last,
      timer: null,
      copyed: false,
      copyedTime: 2000,
    };
  },
  methods: {
    clickCopy() {
      copy(this.item.content);
      this.copyed = true;
      setTimeout(() => {
        this.copyed = false;
      }, this.copyedTime);
    },
    delay() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, this.delayTime);
      });
    },
    /**
     * 字符串是否含有html标签的检测
     * @param htmlStr
     */
    checkHtml(htmlStr) {
      var reg = /<[^>]+>/g;
      return reg.test(htmlStr);
    },
    async typeWriterText(node, eleTarget) {
      if (node.nodeType === Node.TEXT_NODE) {
        const renderText = node.textContent;
        const textLength = renderText.length;
        for (let i = 0; i < textLength; i++) {
          if (this.last) await this.delay();
          const textNode = document.createTextNode(renderText[i]);
          eleTarget.appendChild(textNode);
        }
      } else {
        const tag = node.tagName.toLowerCase();
        const tagEle = document.createElement(tag);
        tagEle.className = node.getAttribute("class") || "";
        eleTarget.appendChild(tagEle);
        const renderText = node.textContent;
        const textLength = renderText.length;
        for (let i = 0; i < textLength; i++) {
          if (this.last) await this.delay();
          tagEle.innerHTML = tagEle.innerHTML + renderText[i];
        }
      }
    },

    async renderTyper(node, target) {
      if (node.nodeType === Node.TEXT_NODE) {
        const brEle = document.createElement("br");
        target.appendChild(brEle);
      } else {
        const tagName = node.tagName.toLowerCase();
        if (tagName === "pre") {
          // 代码块高亮
          const codeClass = node.querySelector("code").getAttribute("class");
          const lang = codeClass && codeClass.replace(/^language-/, "");
          let hljsLang = !lang ? "bash" : lang.replace("-", "");
          const codeText = node.textContent;
          const highlighted = window.hljs.highlight(hljsLang, codeText).value;
          const codeContainerEle = document.createElement("div");
          codeContainerEle.className = "code-container cusor";
          const codeHeader = document.createElement("div");
          codeHeader.className = "code-header";
          codeContainerEle.appendChild(codeHeader);
          const headerLeft = document.createElement("div");
          headerLeft.innerText = lang;
          codeHeader.appendChild(headerLeft);
          const headerRight = document.createElement("div");
          headerRight.className = "header-right";
          const copyHtml = `<img src = ${this.svgIcon}> Copy code`;
          headerRight.innerHTML = copyHtml;
          headerRight.onclick = () => {
            copy(codeText);
            const copyed = `<img src = ${this.yesIcon}> Copied!`;
            headerRight.innerHTML = copyed;
            setTimeout(() => {
              headerRight.innerHTML = copyHtml;
            }, this.copyedTime);
          };
          codeHeader.appendChild(headerRight);
          const preEle = document.createElement("pre");
          const codeEle = document.createElement("code");
          codeEle.className = `!whitespace-pre hljs ${codeClass}`;
          preEle.appendChild(codeEle);
          codeContainerEle.appendChild(preEle);
          target.appendChild(codeContainerEle);

          const allCodeChildNodes = new DOMParser().parseFromString(
            highlighted,
            "text/html"
          ).body.childNodes;
          for (const j of allCodeChildNodes) {
            await this.typeWriterText(j, codeEle);
          }
          codeContainerEle.className = "code-container";
        } else {
          const divEle = document.createElement("div");
          divEle.className = "markdown text-content prose break-words";
          const tagEle = document.createElement(tagName);
          tagEle.className = "cusor";
          divEle.appendChild(tagEle);
          target.appendChild(divEle);
          for (const m of node.childNodes) {
            await this.typeWriterText(m, tagEle);
          }
          tagEle.className = "";
        }
      }
    },
    scrollHtml() {
      const scrollEle = document.querySelector(".content-list");

      if (scrollEle) {
        scrollEle.scrollTop = scrollEle.scrollTop + 50;
      }
      if (!this.canScroll || this.usermoved) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.scrollHtml();
      }, this.delayTime * 10);
    },
    init() {
      this.$nextTick(async () => {
        const target = this.$refs.aiAnswer;
        if (!target) {
          return;
        }
        // 将 Markdown 转换成 HTML
        const md = new window.remarkable.Remarkable();
        const html = md.render(this.item.content);
        try {
          const allChildNodes = new DOMParser().parseFromString(
            html,
            "text/html"
          ).body.childNodes;
          const lastEle = document.querySelector(".lastContent");
          for (let i = 0; i < allChildNodes.length; i++) {
            const node = allChildNodes[i];
            if (this.last && lastEle) {
              // 最后一个需要滚动页面
              this.scrollHtml();
            }
            await this.renderTyper(node, target);
          }
          // 不是最后一个，渲染完成后，需要让最后一个滚动到可视区域
          !this.last && lastEle && lastEle.scrollIntoView();
          this.canScroll = false;
        } catch (error) {
          target.innerHTML = html;
          this.canScroll = false;
        }
      });
    },
  },
  mounted() {
    this.isAi && this.init();
  },
};
</script>

<style lang="scss">
.content-item {
  position: relative;
  color: #fff;
  // margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  .content {
    position: relative;
    z-index: 2;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 27px;
    display: flex;
    .ai-answer {
      width: 100%;
      overflow: hidden;
      .code-container {
        background: #000;
        border-radius: 6px;
        overflow: hidden;
        .code-header {
          display: flex;
          justify-content: space-between;
          padding: 8px 16px;
          font-size: 12px !important;
          background: rgb(52, 53, 65);
          color: rgb(217, 217, 227);
          .header-right {
            display: flex;
            img {
              margin-right: 6px;
            }
          }
        }
      }

      pre {
        overflow-x: auto;
        padding: 16px 16px;
        background: #000;
        &::-webkit-scrollbar {
          height: 4px;
        }
      }

      .text-content {
        width: 100%;
        code {
          color: #111827;
          display: inline;
          max-width: 100%;
          // word-break: break-all;
          font-weight: 600;
        }
      }
    }
  }
  .icon {
    height: 28px;
    width: 28px;
    margin-right: 16px;
  }
  &.user {
    background-color: #ffffff;
    color: #39414f;
    box-shadow: 0 1px 0 0 #e7e7e7;
  }
  &.ai {
    color: #3c4250;
  }

  .cusor::after {
    position: absolute;
    content: "";
    margin-top: 6px;
    width: 8px;
    height: 14px;
    background-color: #000;
    margin-left: 10px;
    animation: blink 0.5s steps(5, start) infinite;
  }
  .cusor.code-container pre {
    padding-bottom: 32px !important;
  }
  .cusor.code-container::after {
    left: 0;
    bottom: 16px;
    background: #fff;
  }
  .copy-botton {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 16px;
    right: 20px;
    z-index: 10;
  }

  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
}
</style>
